<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
        <vx-tooltip text="Cerrar Caja" class="float-left">
            <feather-icon icon="LockIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="clickHandler()" />
        </vx-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActionsRequestsAdmin',
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        // data() {
        //     return {
        //         sidebarActive: false,
        //         dataLocal: {}
        //     }
        // },
        // watch: {
        //     sidebarActive(val) {
        //         if(!val) return
        //         if(Object.entries(this.data).length === 0) {
        //             this.initValues()
        //         } else {
        //             this.dataLocal = JSON.parse(JSON.stringify(this.data))
        //         }
        //     }
        // },
        methods: {
            clickHandler() {
                let data = this.params.data;
                this.params.clicked(data);
            }
        }
    }
</script>
<style>
    .vs-popup--content { 
        height:100% !important;
    }
</style>
