<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Cerrar Caja</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <vs-input label="Usuario Inicio" v-model="dataUsername" class="mt-3 w-full" name="item-username" v-validate="'required'" disabled="disabled" />
          <vs-input label="Fecha Inicio" v-model="dataOpenDate" class="mt-3 w-full" name="item-description" v-validate="'required'" disabled="disabled" />
          <vs-input label="Turno" v-model="dataIdTillInit" class="mt-3 w-full" name="itme-idtillinit" v-validate="'required'" disabled="disabled" />
          <vs-table :model="this.dataCloseDetails" ref="table" :data="dataCloseDetails" class="mt-3">  
              <!-- <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                  <h2>Detalle de Caja</h2>
              </div> -->
              <template slot="thead">
                  <vs-th sort-key="id"></vs-th>
                  <vs-th sort-key="name">Importe</vs-th>
              </template>
              <template slot-scope="{data}" >
                  <tbody>
                      <vs-tr>
                        <vs-td>
                            <p class="font-medium"><strong>Inicio de Caja</strong></p>
                        </vs-td>
                        <vs-td>
                              <p class="product-category">$ {{ dataCashInit }}</p>
                          </vs-td>
                      </vs-tr>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                          <vs-td>
                              <p class="font-medium">{{ tr.sale_type_description }}</p>
                          </vs-td>

                          <!-- <vs-td>
                              <p class="product-name font-medium truncate">{{ tr.qty }}</p>
                          </vs-td> -->

                          <vs-td>
                              <p class="product-category">$ {{ tr.total }}</p>
                          </vs-td>

                          <!-- <vs-td>
                              <p class="product-category">$ {{ tr.sale_total }}</p>
                          </vs-td> -->

                      </vs-tr>
                      <vs-tr>
                          <vs-td>
                              <p class="font-medium"><strong>Total</strong></p>
                          </vs-td>
                          <!-- <vs-td>
                              <p class="product-category"><strong>{{data.reduce((total, s) => total + s.qty, 0)}}</strong></p>
                          </vs-td> -->
                          <!-- <vs-td>
                          </vs-td> -->
                          <vs-td>
                              <p class="product-category"><strong>$ {{dataTotal}}</strong></p>
                          </vs-td>
                      </vs-tr>
                  </tbody>
              </template>
          </vs-table>
          <vs-input label="Total Efectivo" v-model="dataTotalCash" class="mt-3 w-full" name="item-totalcash" disabled="disabled" />
          <vs-input label="En Caja" autocomplete="off" v-model="dataCashEnd" class="mt-3 w-full" name="En_Caja" v-validate="{ required: true, regex: /^\d*\.?\d*$/, min_value: 0, max_value: 99999 }" />
          <span class="text-danger text-sm" v-show="errors.has('En_Caja')">{{ errors.first('En_Caja') }}</span>
          <!-- <vs-input type="text" label="Diferencia"  :value="(dataTotalCash - dataCashEnd).toFixed(2)" class="mt-3 w-full" :class="(dataTotalCash - dataCashEnd != 0) ? 'text-danger text-sm' : ''" name="item-totalcash" disabled="disabled" /> -->
          <div class="vs-component vs-con-input-label vs-input mt-3 w-full vs-input-primary">
            <label for="" class="vs-input--label w-full float-left">Diferencia</label>
            <vs-chip :color="getDifferenceColor(dataTotalCash - dataCashEnd)" class="product-order-status">{{ (dataCashEnd - dataTotalCash).toFixed(2) }}</vs-chip>
          </div>
        </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Cerrar Caja</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { name, open_date, id_till_init, inicio_caja } = JSON.parse(JSON.stringify(this.data))
        this.dataUsername = name
        this.dataOpenDate = new Date(open_date).toLocaleDateString("es-AR", this.$localeDateOptions);
        this.dataIdTillInit = id_till_init
        this.dataCashInit = inicio_caja
        this.dataCashEnd = ""
        this.dataTotalCash = 0
        this.dataTotal = 0
        this.initValues()
        this.loadCloseTillDetails();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataUsername: null,
      dataOpenDate: null,
      dataIdTillInit: null,
      dataCashInit: null,
      dataCashEnd: null,

      dataTotal: null,
      dataTotalCash: null,

      dataCloseDetails: [],

      selected: [],

      // perfectscrollbar settings
      settings: { 
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
        return !this.errors.any() && this.dataUsername && this.dataOpenDate && this.dataIdTillInit && (this.dataCashEnd && this.dataCashEnd > -1)
    },
  },
  methods: {
    initValues() {
      if(this.data.id_till_init) return
        this.dataUsername = null
        this.dataOpenDate = null
        this.dataIdTillInit = null
        this.dataCashInit = null
        this.dataCashEnd = null
    },
    submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {

            const obj = {
              id_sale_init: this.dataIdTillInit,
              sale_te_amount: this.dataCashEnd
            }
            
            if(this.dataIdTillInit !== null && this.dataIdTillInit >= 0) {
               this.$store.dispatch("reportModule/closeTill", obj)
               .then(response => {
                 if(response.status)
                  this.$router.go()
               })
               .catch(err => { console.error(err) })
            }

            this.$emit('closeSidebar')
            this.initValues()
          }
        })
      
    },
    loadCloseTillDetails() {
      if(this.dataIdTillInit) {
        this.$store.dispatch('reportModule/getTillCloseDetail', this.dataIdTillInit)
          .then(response => {
            let close_details = response.data;
            const sale_types = JSON.parse(JSON.stringify(this.$store.state.AppData.saleTypes));
            this.dataTotalCash = this.dataCashInit;

            if(sale_types && sale_types.length > 0) {
              close_details.forEach(e => {
                e.sale_type_description = sale_types.find(d => d.id == e.sale_type).description;
                if(e.sale_type == 3 || e.sale_type == 5) { this.dataTotalCash += e.total }
              })
            }
            this.dataTotal = (close_details.reduce((total, s) => total + s.total, 0) + this.dataCashInit).toFixed(2);
            this.dataCloseDetails = close_details;

          })
          .catch(error => console.log(error))
      }
    },
    getDifferenceColor(val) {
      if(val == 0) return "success"
      if(val != 0) return "danger"
      return "primary"
    }
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
}
</script>

<style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }

    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
      p {
        margin-bottom: 2rem;
        > span {
          font-size: 0.9rem;
        }
      }
    }
  }

  .scroll-area--data-list-add-new {
      // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
      height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  }
  .vs-con-tbody.vs-table--tbody {
    overflow: hidden !important;
  }
</style>